import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { useEffect } from "react";
import { Footer } from "../components/Footer";
const mongoose =require('mongoose');
import Header from "../components/Header";
import {SectionBrands, SectionGallery, SectionHero,SectionModels} from "../components/Mainpage";
import { getMotorCats } from "../controllers/category";
import { getMotorsMP } from "../controllers/models";
import { getTwoNews } from "../controllers/news";
import { getPage } from "../controllers/pages";
//styles
import styles1 from '~/assets/css/uikit.min.css';
import styles2 from '~/assets/css/slick.css';
import styles3 from '~/assets/css/slick-theme.css';
import styles4 from '~/assets/css/ion.rangeSlider.css';
import styles5 from '~/assets/css/main.css';
const useScript = (src,condition) => {  
  useEffect(() => {
          const tag = document.createElement('script');
          tag.async = condition;
          tag.src = src;
          document.body.appendChild(tag);            
          return () => {
            document.body.removeChild(tag);
          }      
  }, [src])  
}
export default function Index() {  
  useScript('/assets/js/jquery.min.js',false);
  useScript('/assets/js/uikit.min.js',false);
  useScript('/assets/js/uikit-icons.min.js',false);
  useScript('/assets/js/fontawesome.all.min.js',false);
  useScript('/assets/js/slick.min.js',false);
  useScript('/assets/js/ion.rangeSlider.min.js',false);
  useScript('/assets/js/main.js',false);
  const data =useLoaderData();
  return (
    <div className="page-wrapper">
    <Header />
    <main className="page-main">
    <SectionHero slider={data.page.slider} />
    <SectionModels motors={data.motors} cats={data.cats}/>
    <SectionGallery photos={data.page.gallery} />
    <SectionBrands />
    </main>
    <Footer news={data.news} />
    </div>
  );
}
export const loader= async () => {  
  await mongoose.connect(process.env.DATABASE_URL);  
 /* const model =new Page({
    pageId:4,
    title: 'Haber Listesi',
    seoD: 'Haber Listesi seod',
    seoK: 'Haber Listesi seokw',
    slider: 'slidernewslist.jpgæTAKING RIDES TO A NEWER LEVELæTYPOGRAPHY',
    gallery: ' ',
    footer:' '
  });
  model.save();*/
  //return await getPage(0);
  const [page, motors,cats,news] = await Promise.all([
    getPage(0),
    getMotorsMP(),
    getMotorCats(),
    getTwoNews()
 ]);
 return json({ page,motors,cats,news });
  
}
export function meta({data}){
   return{
    title:data.page.title,
    description:data.page.seoD,
    keywords:data.page.seoK
  };
}
export function links(){
  return [
    {rel:'stylesheet',href:styles1},
    {rel:'stylesheet',href:styles2},
    {rel:'stylesheet',href:styles3},
    {rel:'stylesheet',href:styles4},
    {rel:'stylesheet',href:styles5}
  ]
}
