import { NavLink } from "@remix-run/react";
import { IMG_URL } from "../helpers/def";
import {Link} from 'react-scroll'


export function SectionHero({slider}){
    return(
        <div className="section-hero">
    <div data-uk-slideshow="autoplay: true; min-height: 560; max-height: 950; animation: fade">
      <div className="uk-position-relative" tabIndex="-1">
        <ul className="uk-slideshow-items">
        {slider.split('æ').map((picture, index) => ( 
          <li key={index} className="slideshow-item"><img src={IMG_URL+'mc/1920/950/6/sliders/'+picture.split('ß')[0]} alt="Honda Alanya Yetkili Bayi" data-uk-cover=""/>
          <div className="uk-position-center uk-position-small">
            <div className="uk-container">
              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-3-5@m">
                  <div className="slideshow-item__content">
                    <div className="slideshow-item__decor" data-uk-slideshow-parallax="opacity: 0,1">Honda</div>
                    <div className="slideshow-item__title" data-uk-slideshow-parallax="x: 100,-100"><span>{picture.split('ß')[1]}</span>
                      <div className="uk-h2">{picture.split('ß')[2]}</div>
                    </div>
                    <div className="slideshow-item__desc" data-uk-slideshow-parallax="x: 200,-200">
                      <p>{picture.split('ß')[3]}</p>
                    </div>
                    <div className="slideshow-item__btns" data-uk-slideshow-parallax="x: 300,-300"><Link spy={true} smooth={true} className="uk-button uk-button-danger"  to="section-models">Modeller </Link></div>
                  </div>
                </div>
                <div className="uk-width-2-5@m uk-visible@l"></div>
              </div>
            </div>
          </div>
        </li>
         ))}
        </ul>
      </div>
      <div className="slideshow-nav"><a href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
        <div className="slideshow-nav__count">
          <ul>
          {slider.split('æ').map((picture, index) => (
              <li key={index} data-uk-slideshow-item={index.toString()}><span>{(index+1).toString()} </span></li>
          ))}
          </ul><span>{' / '+slider.split('æ').length}</span>
        </div><a href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
      </div>
    </div>
  </div>
    )
}
export function SectionModels({motors,cats}){
    return(
        <div className="section-products section-products--2" id="section-models">
        <div className="uk-section-large uk-container">
          <div className="section-title uk-text-center"><img src="metam-logo.png" alt="Alanya Honda Yetkili Bayii" /><span>Hayatınızı Yeni Bir Seviyeye Çıkarmak İçin </span>
            <h3 className="uk-h2">Motosikletinizi Seçin</h3>
          </div>
          <div className="section-content">
            <div className="uk-text-center uk-margin-medium-bottom">
              <p>Kendinizi motosiklet dünyasına hazırlayın ve hayatınızın en unutulmaz yolculuklarına çıkın</p>
            </div>
            <div data-uk-filter=".js-filter">
              <ul className="uk-subnav uk-subnav-pill uk-flex-center uk-margin-medium-bottom">
                <li className="uk-active" data-uk-filter-control="" ><a href="#">Tümü </a></li>
                {cats?.map((cat, index) => (
                  <li key={index} data-uk-filter-control={"[data-tags*="+cat.title+"]"}><a href="#">{cat.title}</a></li>
                ))}
              </ul>
              <ul className="js-filter uk-grid uk-grid-medium uk-child-width-1-3@l uk-child-width-1-2@s" data-uk-grid>
                {motors?.map((motor,index)=>(
                  <li key={index} data-tags={motor.category}>
                  <div className="product-item uk-transition-toggle">
                    <div className="product-item__head">
                      <div>
                        <div className="product-item__name"> <NavLink to={'/honda-model/'+motor.title.replaceAll(' ','-')}>{motor.title}</NavLink></div>
                      </div>
                      <div>
                        <div className="product-item__price">HONDA</div>
                      </div>
                    </div>
                    <div className="product-item__media uk-inline-clip uk-inline"><img src={IMG_URL+'mc/360/300/6/motors/'+motor.cover} alt={motor.title} /><NavLink className="uk-transition-fade" to={'/honda-model/'+motor.title.replaceAll(' ','-')}>
                        <div className="uk-overlay-cover uk-overlay-primary"></div>
                        <div className="uk-position-center"><span uk-icon="icon: plus; ratio: 2"></span></div>
                      </NavLink><button className="product-item__whish btn-whish"><i className="far fa-heart"></i></button></div>
                    <div className="product-item__info">
                      <ul className="list-info">
                        <li className="list-info-item">
                          <div className="list-info-item__title">Tip</div>
                          <div className="list-info-item__value">{motor.category}</div>
                        </li>
                        <li className="list-info-item">
                          <div className="list-info-item__title">Model</div>
                          <div className="list-info-item__value">{motor.title}</div>
                        </li>
                      </ul>
                    </div>
                    <div className="product-item__specifications">
                      <ul className="specifications-list">
                        <li className="specifications-list-item">
                          <div className="specifications-list-item__icon"><img src="assets/img/icons/specifications-1.png" alt="Engine type" /></div>
                          <div className="specifications-list-item__desc">
                            <div className="specifications-list-item__title">Motor Hacmi</div>
                            <div className="specifications-list-item__value">{motor.enginecc}</div>
                          </div>
                        </li>
                        <li className="specifications-list-item">
                          <div className="specifications-list-item__icon"><img src="assets/img/icons/specifications-2.png" alt="Engine Power" /></div>
                          <div className="specifications-list-item__desc">
                            <div className="specifications-list-item__title">Motor Gücü</div>
                            <div className="specifications-list-item__value">{motor.power}</div>
                          </div>
                        </li>
                        <li className="specifications-list-item">
                          <div className="specifications-list-item__icon"><img src="assets/img/icons/specifications-3.png" alt="Displacement" /></div>
                          <div className="specifications-list-item__desc">
                            <div className="specifications-list-item__title">Tork</div>
                            <div className="specifications-list-item__value">{motor.tork}</div>
                          </div>
                        </li>
                        <li className="specifications-list-item">
                          <div className="specifications-list-item__icon"><img src="assets/img/icons/specifications-4.png" alt="Bore/Stroke" /></div>
                          <div className="specifications-list-item__desc">
                            <div className="specifications-list-item__title">Vites</div>
                            <div className="specifications-list-item__value">{motor.transtype}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                ))}                
              </ul>
            </div>
            <div className="uk-text-center uk-margin-medium-top"><NavLink className="uk-button uk-button-danger" to={'/honda-model'}>Tümünü Gör</NavLink></div>
          </div>
        </div>
      </div>
    )
}
export function SectionGallery({photos}){
  return(
    <div className="section-gallery">
        <div className="uk-section uk-container uk-container-large">
          <div className="uk-grid uk-child-width-1-4@m uk-child-width-1-3@s" data-uk-grid data-uk-lightbox>
          {photos.split('æ').map((picture, index) => (
            <div key={index}>
            <div><a href={IMG_URL+'mc/700/525/6/gallery/'+picture}><img className="uk-width-1-1" src={IMG_URL+'mc/320/305/6/gallery/'+picture} alt="img-gallery"/></a></div>
          </div>
          ))}
            
            
          </div>
        </div>
      </div>
  )
}
export function SectionBrands(){
  return(
    <div className="section-brands">
        <div className="uk-section uk-container uk-container-large">
          <div data-uk-slider="autoplay:true">
            <div className="uk-position-relative" tabIndex="-1">
              <ul className="uk-slider-items uk-grid uk-flex-middle uk-text-center uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-5@m">
                <li><img src="assets/img/metintour.jpg" alt="Metin Rour"/></li>
                <li><img src="assets/img/nonstoptransfer.jpg" alt="Nonstop Transfer"/></li>
                <li><img src="assets/img/turkustourism.jpg" alt="Turkus Turizm"/></li>
                <li><img src="assets/img/Domwturcji.jpg" alt="Dom w Turcji"/></li>
                <li><img src="metam-logo-no-color.png" alt="Alanya Honda"/></li>
              </ul>
            </div>
            <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </div>
  )
}
